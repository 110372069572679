<template>
  <div>
    <div :class="$style.wrapper" class="container">
      <ul>
        <li v-for="{ component, title, description } in items" :key="title">
          <component :is="component" :class="$style.icon" />
          <h2 class="h2">{{ title }}</h2>
          <p class="p">{{ description }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
const items = [
  {
    component: resolveComponent("SvgoIconLock"),
    title: "Гарантия безопасности",
    description:
      "Мы даём 100% гарантию перевода. Надежность нашего сервиса подтверждают многочисленные отзывы довольных клиентов на авторитетных ресурсах.",
  },
  {
    component: resolveComponent("SvgoIconClock"),
    title: "Быстрый обмен",
    description:
      "Среднее время обработки заявки в течение 15 минут, зачисление средств зависит от загруженности конкретной сети и прочих факторов.",
  },
  {
    component: resolveComponent("SvgoIconCart"),
    title: "Разнообразие валют",
    description:
      "Наш сервис обеспечивает обширный выбор валют, включая национальные валюты, криптовалюты и стейблкоины. Мы предлагаем разнообразные опции обмена, чтобы удовлетворить потребности наших клиентов.",
  },
];
</script>

<style lang="scss" module>
.wrapper {
  ul {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @include respond-to(sm) {
      gap: 60px;
    }

    li {
      display: flex;
      margin: 0 auto;

      @include respond-to(sm) {
        flex-direction: column;
        align-items: flex-start;
        margin: unset;
        gap: 15px;
      }

      h2 {
        order: 3;
        max-width: 47.5rem;
        width: 100%;

        @include respond-to(sm) {
          order: 2;
          max-width: unset;
        }
      }

      p {
        order: 2;
        max-width: 52.5rem;
        width: 100%;
        margin-right: 8rem;

        @include respond-to(sm) {
          order: 3;
          max-width: unset;
          margin-right: unset;
        }
      }
    }
  }
}

.icon {
  width: auto;
  height: 4.8rem;
  flex-shrink: 0;
  margin-right: 2.7rem;
  color: $base-900;

  @include respond-to(sm) {
    height: 54px;
  }
}
</style>
