<template>
  <swiper-container ref="slider" init="false" @progress="onProgress">
    <slot />
  </swiper-container>
</template>

<script setup lang="ts">
import type { Swiper, SwiperOptions } from "swiper/types";
import type { PropType } from "vue";

type SwiperRef = HTMLElement & { swiper: Swiper; initialize: () => void };
const slider = ref<SwiperRef | null>(null);
const swiper = ref<Swiper>(null);
const isBeginning = ref(false);
const isEnd = ref(false);

const props = defineProps({
  options: {
    type: Object as PropType<SwiperOptions>,
    default: () => ({}),
  },
});

onMounted(() => {
  if (!slider.value) return;
  Object.assign(slider.value, props.options);
  slider.value.initialize();
  swiper.value = slider.value.swiper;
});

onUnmounted(() => {
  if (!swiper.value) return;
  swiper.value.destroy();
});

const onProgress = (e) => {
  const [swiper] = e.detail;
  isBeginning.value = swiper.isBeginning;
  isEnd.value = swiper.isEnd;
};
const slideNext = () => {
  swiper.value.slideNext();
};

const slidePrev = () => {
  swiper.value.slidePrev();
};

defineExpose({
  slideNext,
  slidePrev,
  isBeginning,
  isEnd,
});
</script>
