<template>
  <div :class="$style.Reviews">
    <h2 class="h2 center">Отзывы</h2>
    <div :class="$style.wrapper">
      <CommonSwiperSlider
        ref="swiper"
        :options="options"
        :class="$style.slider"
      >
        <CommonSwiperSlide
          v-for="{ name, review, dateTime, id } in data?.reviews"
          :key="id"
          :class="$style.item"
        >
          <PagesHomeReviewsItem
            :name="name"
            :content="review"
            :date="dateTime"
          />
        </CommonSwiperSlide>
      </CommonSwiperSlider>
    </div>
    <div :class="$style.button">
      <VButton
        to="https://www.bestchange.ru/kotleta-exchanger.html"
        target="_blank"
      >
        Еще больше отзывов
      </VButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SwiperOptions } from "swiper/types";
import { usePagesApi } from "~/api/usePagesApi";
import SwiperSlider from "~/components/common/SwiperSlider.vue";
import VButton from "~/components/ui/Button/VButton.vue";

const { fetchReviews } = usePagesApi();
const { data } = await fetchReviews();

const options: SwiperOptions = {
  slidesPerView: 1.3,
  injectStyles: [
    `
      .swiper {
        overflow: unset;
      }
    `,
  ],
  grid: {
    rows: 1,
    fill: "row",
  },
  spaceBetween: 20,
  breakpoints: {
    768: {
      slidesPerView: 2.2,
      grid: {
        rows: 1,
        fill: "row",
      },
    },
    1024: {
      slidesPerView: 3,
      grid: {
        rows: 1,
        fill: "row",
      },
    },
  },
};
const swiper = ref<InstanceType<typeof SwiperSlider> | null>(null);
</script>

<style lang="scss" module>
.Reviews {
  padding: 0 2.4rem;

  h2 {
    margin-bottom: 7rem;
  }

  @include respond-to(sm) {
    gap: 60px;
  }

  @include respond-to(xs) {
    padding: 110px 16px 100px;
  }
}

.wrapper {
  margin-bottom: 7rem;
}

.slider {
  overflow: unset !important;
}

.item {
  height: auto !important;
}

.button {
  text-align: center;
}
</style>
