<template>
  <div :class="$style.ReviewsItem">
    <div>
      <p class="p" :class="$style.name">{{ name }}</p>

      <p class="p-big" :class="$style.content">{{ content }}</p>
    </div>
    <div :class="$style.date">{{ dates }}</div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  name: {
    type: String,
    default: "",
  },
  content: {
    type: String,
    default: "",
  },
  date: {
    type: String,
    default: "",
  },
});

const dates = computed(() => {
  return convertDate(props.date, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
});
</script>

<style lang="scss" module>
.ReviewsItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  height: 21rem;
  margin: 0 0.4rem;
  padding: 2.5rem 1.5rem 2.5rem 2.5rem;
  border: 2px solid $body-color;
  cursor: pointer;
}

.content {
  height: 10rem;
  overflow: auto;
  padding-right: 1rem;
}

.name {
  margin-bottom: 1rem;
  color: $base-500;
}

.date {
  font-size: 1.4rem;
  color: $base-500;
}

.wrapper {
  overflow: auto;
}
</style>
