<template>
  <div :class="$style.TechMode">
    <img src="/images/home/star-tech.svg" alt="tech" :class="$style.icon" />
    <div :class="$style.column">
      <p v-for="item in text" :key="item" :class="$style.text">
        {{ item }}
      </p>
    </div>
    <UiButtonVButton @click="toAccount">В личный кабинет</UiButtonVButton>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  time: {
    type: Number,
    default: 0,
  },
});

const { isLogged } = useUser();
const router = useRouter();

function toAccount() {
  if (isLogged.value) {
    router.push("/account/dashboard");
  } else {
    router.push("/login");
  }
}

const now = useNow();
const endTime = ref<Date | null>(null);

function initTimer(seconds: number) {
  endTime.value = new Date(now.value.getTime() + seconds * 1000);
}

const timeLeft = computed(() => {
  if (!endTime.value) return 0;
  const secondsLeft = Math.floor(
    (endTime.value.getTime() - now.value.getTime()) / 1000,
  );
  if (secondsLeft <= 0) return 0;
  return formatTime(secondsLeft);
});

initTimer(props.time);

const text = computed(() => {
  return [
    "Обмен не доступен в данный момент по техническим причинам",
    "Возвращайтесь через " + timeLeft.value,
  ];
});
</script>

<style lang="scss" module>
.TechMode {
  padding: 0 9.4rem;
  border: 2px solid var(--body-color);
  background: var(--techmode-bg);
  z-index: 3;
  text-align: center;
  backdrop-filter: blur(7.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include respond-to(sm) {
    padding: 0 43px;
  }

  @include respond-to(xs) {
    padding: 0 20px;
  }
}

.icon {
  width: 18.4rem;
  margin-bottom: 2rem;

  @include respond-to(xs) {
    margin-bottom: 60px;
  }
}

.text {
  font-family: $font-family-secondary;
  font-size: 2.4rem;
  line-height: 140%;
  letter-spacing: -0.06rem;

  @include respond-to(xs) {
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.4px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  gap: 2rem;

  @include respond-to(xs) {
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.4px;
    margin-bottom: 60px;
  }
}
</style>
