<template>
  <div :class="[$style.ContestBlock, { [$style._close]: close }]">
    <NuxtLink to="/promocode">
      <span :class="$style.link" />
    </NuxtLink>
    <div :class="$style.title">Конкурс</div>
    <p :class="$style.description">Оставь отзыв и получи промокод на обмен</p>
    <div :class="$style.close" @click="onCloseClick">
      <SvgoIconClose />
    </div>
    <img :class="$style.imageFirst" src="/images/money.gif" alt="money" />
    <img :class="$style.imageSecond" src="/images/money.gif" alt="money" />
  </div>
</template>

<script setup lang="ts">
const close = ref(false);

const onCloseClick = () => {
  close.value = !close.value;
};
</script>

<style lang="scss" module>
.ContestBlock {
  position: relative;
  background: $brand-green;
  height: 100%;
  padding: 3.7rem 2rem;
  overflow: hidden;
  clip-path: polygon(33px 100%, 33px 70%, 0% 70%, 0% 0%, 100% 0%, 100% 100%);

  &._close {
    display: none;
  }
}

.title {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  color: $base-1000;
}

.description {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;
  color: $base-1000;
}

.imageFirst {
  position: absolute;
  bottom: -20px;
  right: 38px;
  width: 12rem;
  height: auto;
}

.imageSecond {
  position: absolute;
  bottom: 29px;
  right: -11px;
  width: 7.4rem;
  height: auto;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-color;
  width: 3.3rem;
  height: 3.3rem;
  z-index: 2;
  cursor: pointer;

  svg {
    width: 2rem;
    height: auto;
    color: $brand-green;
  }
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>
