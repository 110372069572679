<template>
  <div :class="$style.Exchange">
    <img
      src="/images/background/lines.svg"
      alt="lines"
      draggable="false"
      :class="$style.background"
    />
    <div :class="$style.wrapper" class="container">
      <h1 class="h2 center" :class="$style.title">
        Безопасный обмен криптовалют
      </h1>
      <div :class="$style.wrap">
        <client-only>
          <ExchangeOperatingMode
            :class="$style.mode"
            :is-weekend="isWeekend"
            is-desktop
          ></ExchangeOperatingMode>
        </client-only>
        <div :class="$style.inner">
          <PagesHomeExchangeContainer :step="step">
            <template #content>
              <PagesHomeExchangeBlock
                :step="step"
                :class="$style.content"
                :is-weekend="isWeekend"
                :is-button-blocked="isTechModeOn"
              />
            </template>
          </PagesHomeExchangeContainer>
          <transition name="fade">
            <PagesHomeExchangeTechMode
              v-if="isTechModeOn"
              :time="time"
              :class="$style.techmode"
            />
          </transition>
          <!-- <transition name="fade">
            <PagesHomeExchangeOperatingModeInfo
              v-if="!isTechModeOn && isCalculatorBlocked"
              :class="$style.info"
              :is-weekend="isWeekend"
              @unblock-calculator="agree = true"
            />
          </transition> -->
        </div>
        <client-only>
          <CommonContestBlock :class="$style.contest" />
        </client-only>
        <PagesHomeExchangePairs v-if="false" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import moment from "moment-timezone";
import { useTech } from "~/composables/useTech";
import ExchangeOperatingMode from "~/components/pages/home/exchange/ExchangeOperatingMode.vue";

const step = ref(1);

const { on: isTechModeOn, time } = useTech();

// const agree = ref(false);

const now = useNow({
  interval: 10000,
});

const moscowTime = computed(() => moment(now.value).tz("Europe/Moscow"));

// const moscowHour = computed(() => moscowTime.value.hour());
const moscowDayOfWeek = computed(() => moscowTime.value.day());

const isWeekend = computed(() => {
  const day = moscowDayOfWeek.value;
  return day === 0 || day === 6;
});

// const isCalculatorBlocked = computed(() => {
//   const hour = moscowHour.value;
//   const weekend = isWeekend.value;

//   if (agree.value) {
//     return false;
//   }

//   if (weekend) {
//     return !(hour >= 10 && hour < 22);
//   } else {
//     return !(hour >= 8 && hour < 24);
//   }
// });
</script>

<style lang="scss" module>
.Exchange {
  position: relative;
  width: 100%;
  min-height: 50rem;
  margin-top: -17rem;
  padding: 17rem 0 13rem;
  background: $body-color;

  @include respond-to(sm) {
    margin-top: -110px;
    padding: 110px 0 130px;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 1px;
    width: 100%;
    min-height: calc(50rem - 1px);
    height: 100%;
    background: $bg-color;
  }
}

.Exchange,
.Exchange::before {
  clip-path: polygon(
    0% 94%,
    0% 0%,
    100% 0%,
    100% 100%,
    48% 100%,
    40% 94%,
    24% 94%,
    22% 97%,
    7% 97%,
    5% 94%
  );

  @include respond-to(sm) {
    clip-path: polygon(
      0% 0%,
      5% 0%,
      7% 4%,
      22% 4%,
      24% 0%,
      40% 0%,
      48% 4%,
      100% 4%,
      100% 100%,
      0% 100%
    );
  }

  @include respond-to(xs) {
    clip-path: polygon(
      0% 0%,
      5% 0%,
      7% 2%,
      22% 2%,
      24% 0%,
      40% 0%,
      48% 2%,
      100% 2%,
      100% 100%,
      0% 100%
    );
  }
}

.background {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.02;
  filter: $invert;
}

.wrapper {
  position: relative;
  z-index: 1;
}

.title {
  margin-bottom: 4rem;
}

.wrap {
  position: relative;
  max-width: 98rem;
  margin: 0 auto;

  @include respond-to(md) {
    max-width: unset;
  }
}

.techmode {
  position: absolute;
  inset: 0;
}

.mode {
  position: absolute;
  left: -20rem;

  @include respond-to(md) {
    display: none;
  }
}

.contest {
  position: absolute;
  right: -22rem;
  max-width: 21rem;
  max-height: 20.7rem;
  top: 0;

  @include respond-to(md) {
    display: none;
  }

  @include respond-to(xs) {
    position: relative;
    right: unset;
    display: block;
    max-width: unset;
    min-height: 20.5rem;
    margin-bottom: 2rem;
  }
}

.inner {
  position: relative;
}

.content {
  padding: 3rem 2rem 6.4rem;

  @include respond-to(xs) {
    padding: 30px 32px 60px;
  }
}

.info {
  position: absolute;
  inset: 0;
}
</style>
