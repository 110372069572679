<template>
  <div :class="$style.Contest">
    <img
      src="/images/background/lines.svg"
      alt="lines"
      draggable="false"
      :class="$style.background"
    />
    <div :class="$style.wrapper" class="container">
      <h2 class="h2 center">Участвуй в конкурсе и выигрывай денежные призы</h2>
      <ul>
        <li
          v-for="{ image, title, description, link, active } in items"
          :key="title"
        >
          <img :src="image" :alt="title" :class="$style.icon" />
          <h3 class="h3 center">{{ title }}</h3>
          <p class="p center">{{ description }}</p>
          <NuxtLink
            :to="active === true ? link : '/'"
            class="center"
            :class="{ inactive: !active }"
            >Подробнее</NuxtLink
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
const items = [
  {
    image: "/images/home/contest/monster.svg",
    title: "Еженедельный розыгрыш",
    description:
      "Соверши обмен, оставь отзыв и получи возможность выиграть денежный приз каждую неделю",
    link: "/contest",
    active: true,
  },
  {
    image: "/images/home/contest/ghost.svg",
    title: "Промокод на обмен",
    description:
      "Оставь отзыв на наш сервис на доступном мониторинге и получи гарантированный промокод на следующий обмен",
    link: "/promocode",
    active: true,
  },
  {
    image: "/images/home/contest/dino.svg",
    title: "Постоянная скидка",
    description:
      "Пользуйся сервисом и обменивай валюты, скидка будет расти с каждым обменом, начиная с первого",
    link: "/discounts",
    active: true,
  },
];
</script>

<style lang="scss" module>
.Contest {
  position: relative;
  width: 100%;
  min-height: 70rem;
  background: $brand-green;
  overflow: hidden;
}

.Contest,
.Contest::before {
  clip-path: polygon(
    0% 95%,
    0% 5%,
    5% 5%,
    7% 0%,
    23% 0%,
    25% 5%,
    80% 5%,
    82% 0%,
    100% 0%,
    100% 100%,
    20% 100%,
    18% 95%
  );

  @include respond-to(sm) {
    clip-path: polygon(
      0% 97%,
      0% 3%,
      5% 3%,
      9% 1%,
      23% 1%,
      27% 3%,
      80% 3%,
      87% 0%,
      100% 0%,
      100% 100%,
      20% 100%,
      12% 97%
    );
  }
}

.background {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.25;
}

.wrapper {
  position: relative;
  z-index: 1;

  h2 {
    margin-bottom: 5rem;
    color: $base-900;

    @include respond-to(sm) {
      margin-bottom: 60px;
    }
  }

  ul {
    display: flex;
    gap: 7rem;

    @include respond-to(sm) {
      flex-direction: column;
      align-items: center;
      gap: 60px;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc((100% - 14rem) / 2);

      @include respond-to(sm) {
        width: 100%;
        max-width: 380px;
      }

      h3,
      p {
        margin-bottom: 1rem;
        color: $base-900;
      }

      a {
        color: $base-900;
        font-weight: 700;
        font-size: 2rem;
        text-decoration: underline;

        @include respond-to(sm) {
          font-size: 18px;
        }
      }
    }
  }
}

.icon {
  width: auto;
  height: 12rem;
  margin-bottom: 3rem;
  color: $base-900;

  @include respond-to(xs) {
    height: 90px;
    margin-bottom: 15px;
  }
}
</style>
